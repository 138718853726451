section {
  margin-top: 30px;
}

.h-primary {
  font-family: math;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
  padding-bottom: 9px;
}

.contact-text {
  font-size: 0.8rem;
}

.checked {
  color: #f9a710;
}

.skill-box {
  background-color: white;
}
