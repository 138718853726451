@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600&family=Open+Sans:wght@400;500&family=Roboto+Mono:wght@500;600&display=swap");

:root {
  --main-primary-color: #000000;
  --main-secondary-color: #190f21;
  --background-color: #fcfafa;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--background-color);
  color: var(--main-primary-color);
}

/* navabr  */
.nav {
  background-color: var(--background-color);
}

.nav-link {
  font-family: "Roboto Mono", monospace;
  color: var(--main-primary-color);
}

.featurette-heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 3rem;
}

/* footer  */
footer {
  background-color: var(--main-primary-color);
  font-family: "Noto Sans", sans-serif;
  color: #ffffff;
}

.footerImg {
  width: 15vw;
}

.address {
  font-size: 1.2rem;
}
