:root {
  --main-color: #5a20cb;
}

.h-primary {
  margin: 30px 0;
  padding-bottom: 15px;
  color: var(--main-color);
  border-bottom: 3px solid black;
}

.contact-text {
  font-size: 0.8rem;
  margin-left: 2px;
}

.text-purple {
  color: var(--main-color);
}

li {
  font-size: 0.9rem;
}

.skill-box {
  background-color: #c6c6c6;
  margin-bottom: 15px;
  margin-right: 13px;
  padding: 0.2rem 0.9rem;
  border-radius: 8px;
}

@media screen and (max-width: 992px) {
  .contact-text {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 1.3rem;
  }
}
