.h-primary {
  font-size: 1.3rem;
}

.secondary-text {
  font-size: 0.8rem;
}

.checked {
  color: rgba(255, 166, 0, 0.918);
}

@media screen and (max-width: 450px) {
  header {
    flex-direction: column;
  }
  .skill-star,
  .language-star {
    margin-left: 10px !important;
  }
  .fa-star {
    width: 15px;
  }
}
