#left-container {
  background-color: #243e78;
  color: white;
}

.brand {
  background-color: #207398;
  width: 90%;
  height: 10%;
}

.logo {
  width: 100%;
  height: 100%;
  display: block;
}

.h-secondary {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}

.h-third {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
}

.contact-text {
  font-size: 0.6rem;
}

#awards li {
  font-size: 0.7rem;
}

.text-blue {
  color: rgb(12, 180, 247);
}

@media screen and (max-width: 770px) {
  .contact-box small {
    font-size: 10px;
    margin-left: 1px !important;
  }
  .contact-box-4 {
    display: flex;
    flex-direction: column;
  }
  .fa-circle {
    width: 2px;
    margin-right: 6px;
  }
  .skill-box small {
    margin-left: 15px !important;
  }
}
