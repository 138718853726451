#parent {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.edit-container {
  margin-top: 10vh;
  padding-top: 5vh;
  background-color: #f9f6fb;
  border-radius: 30px;
}

.bg-white {
  background-color: #eeeeee;
}

.empty-box {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #00000029;
  font-size: 1.3rem;
  padding: 0 6%;
}

.empty-box h5 {
  color: #d0cfd3;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
}

.pencil-box {
  background-color: rgb(90, 32, 203);
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px 5px;
  border-radius: 2px;
  width: 4%;
  cursor: pointer;
}

.pencil {
  width: 12px;
  color: whitesmoke;
  filter: invert(1);
}
